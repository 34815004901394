/* Colors "imported" from DarkTheme.js file  */
:root {
  --black: #000000;
  --lightBlack: #1f1e1e;
  --lighterBlack: #2c2c2c;
  --white: #ffffff;
  --gray: #4a4a4a;
  --lightGray: #bbbbbb;
}

html,
body {
  background-color: var(--black);
  color: var(--white);
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  position: absolute;
  left: 0px;
  height: 20vmin;
  pointer-events: none;
  margin: 10px;
  /*  content: url("/images/logo.png"); */
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--white);
  border-radius: 10px;
}

a {
  color: var(--white);
  text-decoration: none;
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/
.App-header {
  /*  background-image: url('/images/generisk-BM3_2@2x.png'); */
  /* background-repeat: no-repeat;
  background-size: 100%;
  background-color: #151313;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: normal;
  font-size: calc(10px + 2vmin);
  color: white; */
}
/* 
  Margin top needs to be ralative to image height, but we only know image width is the same for both images.
  But by calculating the image ratio 823/5538*100 = 14.86 we can make the margin top relative to height.
*/
/* This styling can be found in HeaderOverlay.js where it is loaded from. */
/* .App-header-overlay {
  margin-top: -17.5vw;
  z-index: 200;
  width: 100vw;
  min-height: 206px;
  max-height: 206px;
  height: 12rem;

  background-image: url("/images/Path-102@2x.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: transparent;
  align-self: flex-end;
  position: absolute;
  left: -8rem;
} */

/* .App-header-menu {
  position: absolute;
  z-index: 100;
  margin-right: 10px;
  justify-content: normal;
  display: flex;
  flex-direction: column-reverse;
  align-items: left;
  flex-grow: 1;
  align-self: flex-end;
  text-align: right;
}

.menu {
  align-self: flex-end;
} */

.page {
  padding: 10px;
}

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Api-header {
  min-height: 50%;
  padding-bottom: 10px;
}

/* https://css-tricks.com/snippets/css/media-queries-for-standard-devices/ */

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .App-header {
    /* background-image: url("/images/generisk-BM3_2.png"); */
  }
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .App-header {
    /* background-image: url("/images/generisk-BM3_2.png"); */
  }
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .App-header {
    /* background-image: url("/images/generisk-BM3_2.png"); */
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .App-header {
    /* background-image: url("/images/generisk-BM3_2.png"); */
  }
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .App-header {
    /* background-image: url("/images/generisk-BM3_2.png"); */
  }
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 26px;
  right: 36px;
  top: 19px;
}

@media (min-width: 960px) {
  .bm-burger-button {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background-color: var(--white);
  height: 13% !important;
}

/* Color/shape of burger icon bars on hover*/
/* .bm-burger-bars-hover {
  background: #a90000;
} */

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  top: 20px !important;
  right: 20px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: var(--lightBlack);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  /* color: #b8b7ad; */
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  /* background: rgba(0, 0, 0, 0.3); */
}
